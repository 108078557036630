<template>
    <HeaderJourney />

    <div class="main-listing-page compare-sheet-page">
        <div class="container">
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="flow-option-back flow-option-back-listing">
                        <ul class="flow-option-back-ul">
                            <li class="flow-option-back-li" @click="back_to_quotations_listing_page">
                                <i class="pi pi-arrow-left"></i>
                                Back
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="grid ml-0 mr-0 mb-3">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="compare-sheet-header">
                        <h2>Compare Insurance Options</h2>

                        <div class="compare-sheet-header-btn">
                            <button-prime label="Download" icon="pi pi-download" :loading="download_button_loading" @click=download_compare_pdf />
                            <button-prime label="Email Sheet" icon="pi pi-check" :loading="email_button_loading" @click=email_compare_pdf />
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="compare-sheet-table-conrainer">
                        <table class="spreadsheet">
                            <thead>
                                <tr>
                                    <th colspan="1"></th>
                                    <th scope="col" v-for="(plan_detail, index) in plan_details" :key="index">
                                        <div class="compare-image-box">
                                            <img :src="plan_detail.company.company_logo">
                                        </div>
                                        <h2 class="insurance-name compare-head-title">
                                            {{ plan_detail.company.name }}
                                        </h2>
                                    </th>
                                </tr>
                                
                            </thead>
                            
                            <tbody>
                                <tr>
                                    <th scope="row">Plan</th>
                                    <td v-for="(plan_detail, index) in plan_details" :key="index">
                                         {{ plan_detail.name }}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Premium Amount</th>
                                    <td v-for="(plan_detail, index) in plan_details" :key="index">
                                        AED {{ plan_detail.premium }} Excl. VAT
                                    </td>
                                </tr>
                                <tr v-for="benefit in benefits" :key="benefit">
                                    <th scope="row">{{ benefit.name }}</th>
                                    <td v-for="(plan_detail, index1) in plan_details" :key="index1" :data-benefit-id="benefit.id" :data-benefit-index="index1">-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0 mb-4 mt-3">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="compare-sheet-header justify-content-center">
                        <div class="compare-sheet-header-btn">
                            <button-prime label="Download" icon="pi pi-download" :loading="download_button_loading" @click="download_compare_pdf" />
                            <button-prime label="Email Sheet" icon="pi pi-check" :loading="email_button_loading" @click="email_compare_pdf" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0 comparison-footer-info">
                <div class="col-12 md:col-6 lg:col-6">
                    <div class="comparison-bottom-left-info">
                        <img src="../../assets/images/Icons/quesIcon.svg">
                        <h2>Frequently Asked <br> Questions</h2>
                    </div>
                </div>

                <div class="col-12 md:col-6 lg:col-6">
                    <div class="faq-accordion-card mt-0">
                        <accordion-prime>
                            <accordionTab-prime
                                header="How does the benefit differ between Adamjee Insurance and SecureGuard Insurance?">
                                <p class="m-0">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et
                                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut
                                    aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                    dolore eu fugiat
                                    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
                                    qui
                                    officia deserunt
                                    mollit anim id est laborum.
                                </p>
                            </accordionTab-prime>
                            <accordionTab-prime
                                header="How does customer support accessibility differ between the two insurance providers?">
                                <p class="m-0">
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                                    doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                                    architecto
                                    beatae vitae dicta
                                    sunt explicabo. Nemo enim
                                    ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
                                    consequuntur magni dolores
                                    eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia
                                    non
                                    numquam eius
                                    modi.
                                </p>
                            </accordionTab-prime>
                            <accordionTab-prime
                                header="What additional benefits and discounts do Adamjee Insurance and SecureGuard Insurance offer?">
                                <p class="m-0">
                                    At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                                    praesentium voluptatum
                                    deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                                    cupiditate non
                                    provident, similique sunt in culpa qui
                                    officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                                    rerum facilis est et
                                    expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio
                                    cumque
                                    nihil impedit quo
                                    minus.
                                </p>
                            </accordionTab-prime>
                            <accordionTab-prime
                                header="Does MECompare offer any additional after purchase services or benefits beyond basic benefit?">
                                <p class="m-0">
                                    At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                                    praesentium voluptatum
                                    deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                                    cupiditate non
                                    provident, similique sunt in culpa qui
                                    officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                                    rerum facilis est et
                                    expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio
                                    cumque
                                    nihil impedit quo
                                    minus.
                                </p>
                            </accordionTab-prime>
                        </accordion-prime>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <FooterView />
    <toast-prime />
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'
import FooterView from '../Footer/FooterView.vue'
import axios from 'axios'
import $ from 'jquery'
export default {
    data() {
        return {
            compare_data : localStorage.getItem('mebrokers.customer.medical.plans.comparison') != null ? JSON.parse(localStorage.getItem('mebrokers.customer.medical.plans.comparison')) : [],
            benefits : [],
            plan_details : [],
            email_button_loading : false,
            download_button_loading : false
        }
    },    
    components: {
        HeaderJourney
    },
    mounted() {
        this.get_benefits();
    },
    methods: {
        back_to_quotations_listing_page : function() {
            this.$router.push({
                path : '/medical/plan-listing/'+this.$route.params.quotation_id,
            });
        },
        get_benefits : function () {
            axios.get(this.api_url+'medical/get_benefits').then((response) => {
                this.benefits = response.data.data.benefits;
                this.get_plan_details_to_compare();
            });
        },
        download_compare_pdf : function () {
            this.download_button_loading = true;
            window.open(this.api_url+'medical/download-company-plan-comparison/?quotation_id='+this.$route.params.quotation_id+'&compare_data='+JSON.stringify(this.compare_data), '_blank');
            this.download_button_loading = false;
        },
        email_compare_pdf : function () {
            this.email_button_loading = true;
            axios.get(this.api_url+'medical/send_comparison_email',{
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    compare_data : this.compare_data
                }
            }).then((response) => {
                if(response.data.data.status) {
                    this.$toast.add({ severity: 'success', detail: response.data.data.message, life: 3000 });
                    this.email_button_loading = false;
                }
            });
        },
        get_plan_details_to_compare : function() {
            axios.get(this.api_url+'medical/compare_plans',{
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    compare_data : this.compare_data
                }
            }).then((response) => {
                this.plan_details = response.data.data;
                console.log('this.plan_details', this.plan_details);
                setTimeout(() => {
                    $.each(this.plan_details, function(plan_index, plan_value) {
                        $.each(plan_value.plan_benefits, function(benefit_index, benefit_value){
                            if (benefit_value.status == 'included') {
                                if (benefit_value.value != '' && benefit_value.value != null) {
                                    $('[data-benefit-id="'+benefit_value.benefit_id+'"][data-benefit-index="'+plan_index+'"]').text(benefit_value.value)
                                } else {
                                    $('[data-benefit-id="'+benefit_value.benefit_id+'"][data-benefit-index="'+plan_index+'"]').html('<img src="'+plan_value.tick_icon+'">')
                                }
                            }
                            if (benefit_value.status == 'not_included') {
                                $('[data-benefit-id="'+benefit_value.benefit_id+'"][data-benefit-index="'+plan_index+'"]').html('<img src="'+plan_value.cross_icon+'">')
                            }
                            if (benefit_value.status == 'optional') {
                                $('[data-benefit-id="'+benefit_value.benefit_id+'"][data-benefit-index="'+plan_index+'"]').text('AED '+benefit_value.amount)
                            }
                        })
                    })
                }, "2000");
                
            });
        }
    }
}
</script>

<script setup>
// import { ref } from 'vue';

</script>

<style>

</style>