<template>
    <HeaderJourney />

    <div class="main-listing-page main-medical-listing-page">
        <div class="container">
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="flow-option-back flow-option-back-listing">
                        <ul class="flow-option-back-ul">
                            <li v-if="$route.query.is_from == undefined" class="flow-option-back-li"
                                @click="go_back_to_medical_journey_page('current_insurance_company')">
                                <i class="pi pi-arrow-left"></i>
                                Back
                            </li>
                            <li class="inquiry-number">
                                <span>Inquiry Number:</span> <span>{{ ('quotation_number' in quotation) ?
                                    quotation.quotation_number : '-'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0 flow-info-editable-box">
                <div class="col-12 md:col-12 lg:col-12">
                    <swiper :slides-per-view="5" :space-between="0" setWrapperSize="ture" :navigation="true" :loop="true"
                    :pagination="pagination" :modules="modules" :breakpoints="{
                            '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                            '@0.75': { slidesPerView: 2, spaceBetween: 0 },
                            '@1.00': { slidesPerView: 5, spaceBetween: 0 },
                            '@1.50': { slidesPerView: 5, spaceBetween: 0 },
                    }" @swiper="onSwiper" @slideChange="onSlideChange">
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-one') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>1</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Coverage Type</p>
                                                <h4 class="flow-type-title">{{ ('coverage_type' in quotation) ? quotation.coverage_type.name : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div class="flow-edit-box" @click="go_back_to_medical_journey_page('coverage_type')">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-two') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>2</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Emirates</p>
                                                <h4 class="flow-type-title">{{ ('city' in quotation) ? quotation.city.name : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div class="flow-edit-box" @click="go_back_to_medical_journey_page('city')">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-three') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>3</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Critical Medical History</p>
                                                <h4 class="flow-type-title">{{ ('critical_medical_history' in quotation) ? quotation.critical_medical_history : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div class="flow-edit-box" @click="go_back_to_medical_journey_page('critical_medical_history')">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-four') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>3</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Salary Range</p>
                                                <h4 class="flow-type-title">{{ ('salary_range' in quotation) ? quotation.salary_range.name : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div class="flow-edit-box" @click="go_back_to_medical_journey_page('salary_range')">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li"
                                        v-bind:class="(step == 'flow-five') ? 'flow-active' : ''">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>4</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Current Active Policy</p>
                                                <h4 class="flow-type-title">{{ ('current_active_policy' in quotation) ? quotation.current_active_policy : '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div class="flow-edit-box" @click="go_back_to_medical_journey_page('current_active_policy')">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    </swiper>
                </div>
            </div>

            <div class="grid ml-0 mr-0 mb-4">
                <div class="col-12 md:col-4 lg:col-3 listing-filter-area">
                    <div class="left-filters-area">
                        <div class="filter-options-area">
                            

                            <div class="filter-option-list mt-0">
                                <h4>Hospital Network</h4>

                                <div class="flex medical-filter-dropdown">
                                    <multiSelect-prime @change="get_plans" v-model="hospital_network_filters_selected" :options="hospital_network_filters" optionLabel="name" placeholder="Select Hospital Network" :maxSelectedLabels="5" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Copay</h4>

                                <div class="flex medical-filter-dropdown">
                                    <multiSelect-prime @change="get_plans" v-model="co_pay_option_filters_selected" :options="co_pay_option_filters" optionLabel="percentage" placeholder="Select Copay" :maxSelectedLabels="5" class="w-full">
                                        <template #option="slotProps">
                                            <span>{{slotProps.option.percentage}} {{ slotProps.option.label }}</span>
                                        </template>
                                    </multiSelect-prime>    
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Inpatient/Outpatient</h4>

                                <div class="flex medical-filter-dropdown">
                                    <multiSelect-prime @change="get_plans" v-model="io_filters_selected" :options="io_filters" optionLabel="name" placeholder="Select Inpatient/Outpatient" :maxSelectedLabels="5" class="w-full" />
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-8 lg:col-9">
                    <div class="right-listing-items-area">
                        <div class="listing-tabs-container">
                            <button-prime class="filters p-0 listing-filter-btn-mobile" aria-label="filters"
                                @click="visible = true">
                                <span class=" px-3">Filter</span>
                                <img src="../../assets/images/sort.svg">
                            </button-prime>

                            <!-- <div class="listing-tab-panel">
                                <div class="listing-tab-btn active">
                                    Premium
                                </div>
                                <div class="listing-tab-btn">
                                    Basic
                                </div>
                            </div> -->
                            
                            <div class="listing-sort-container">
                                <dropdown-prime @change="get_plans()" v-model="selected_sort"
                                    :options="sort" optionLabel="name"
                                    placeholder="Sort By" class="w-full" />
                            </div>
                        </div>

                        <div class="tabs-listing-content">
                            <!-- Motor listing -->
                            <div class="tabs-listing-box" v-for="(plan, index) in plans" :key="index">
                                <div class="listing-top-header">
                                    <div class="logo-box-container">
                                        <div class="logo-box">
                                            <img :src="plan.company_logo">
                                        </div>
                                        <div class="info-listing-text">
                                            <p class="company-name-text">{{ plan.company_name }}</p>
                                            <p class="plan-name-text">{{ plan.plan_name }}</p>
                                        </div>
                                    </div>
                                    <div class="header-info-right">
                                        <div class="currency-info">
                                            <div class="annual-premium">
                                                <span class="regular-text">Annual Premium</span>
                                                <span class="bold-text">AED {{plan.gross_premium}}</span>
                                            </div>
                                            <div class="vat">
                                                <span class="regular-text">VAT (5%)</span>
                                                <span class="bold-text">AED {{plan.tax_amount}}</span>
                                            </div>
                                        </div>
                                        <div class="btn-right-area">
                                            <small>Pay in 4 installments at AED 34.09/month</small>
                                            <button-prime label="Buy Now" class="buy-now-btn" :loading="loading_buy_now_button"
                                            @click="update_quotation_details(plan)" />
                                            <div class="footer-download-send-btn">
                                                <button-prime icon="pi pi-download" severity="secondary" @click="generate_pdf(plan)" outlined />
                                                <button-prime icon="pi pi-send" severity="secondary" outlined />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="listing-main-content">
                                    <div class="middle-content-box medi-middle-content-box">
                                        <div class="icon-area">
                                            <img src="../../assets/images/Icons/network.svg">
                                        </div>
                                        <div class="info-area">
                                            <h4>Network Coverage</h4>
                                            <p>Hospitals, Clinics</p>

                                            <router-link class="icon-area-anchor" :to="'/medical/plan/hospital-network/'+plan.plan_id">
                                                See Network
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="middle-content-box medi-middle-content-box">
                                        <div class="icon-area">
                                            <!-- <img src="../../assets/images/Icons/cross.svg"> -->
                                        </div>
                                        <div class="info-area">
                                            <h4>Inpatient/Outpatient?</h4>
                                            <p>
                                                <span v-for="(item, itemindex) in plan.plan_type" :key="itemindex">
                                                    {{ item }}{{ (itemindex==plan.plan_type.length-1 ? '' : ', ') }}
                                                </span>
                                            </p>

                                           
                                        </div>
                                    </div>
                                    <div class="middle-content-box medi-middle-content-box">
                                        <div class="icon-area">
                                            <!-- <img src="../../assets/images/Icons/cross.svg"> -->
                                        </div>
                                        <div class="info-area">
                                            <h4>Copay Covered</h4>
                                            <p>{{ plan.plan_co_pay_percentage }}%</p>

                                            <!-- <a class="icon-area-anchor" href="#" @click="fullDetailMaternityVisible = true">See full details</a> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="listing-footer">
                                    <div class="footer-likn-text">
                                        <a href="#">
                                            Terms & Condition
                                        </a>
                                    </div>
                                    <div class="footer-collapse">
                                        <button-prime class="panel-open-btn" label="Explore Benefits and Ad-Ons"
                                            severity="secondary" outlined />
                                    </div>
                                    <div class="compare-box">
                                        <button-prime class="p-0 transparent" aria-label="compare" @click="open_compare_footer(plans[index])">
                                            <span class="px-2">Add to Compare</span>
                                            <i class="pi pi-plus px-2"></i>
                                        </button-prime>
                                    </div>
                                </div>

                                <div class="listing-footerInfo">
                                    <accordion-prime v-model:activeIndex="active">
                                        <accordionTab-prime>
                                            <h2 class="listing-footerInfo-title">What's Included</h2>

                                            <div class="includes-info-list-container">
                                                <template v-for="included_benefit in plans[index].plan_benefits" :key="included_benefit" >
                                                    <div class="includes-info-list"  v-if="included_benefit.benefit_status=='included'">
                                                        <div class="includes-info-list-icon">
                                                        </div>
                                                        <h2 class="includes-info-title">- {{ included_benefit.benefit_name }}
                                                            <span v-if="included_benefit.benefit_value!=null">({{included_benefit.benefit_value}})</span>
                                                        </h2>
                                                    </div>
                                                </template>
                                            </div>

                                            <h2 class="listing-footerInfo-title mt-5">Choose the add ons</h2>

                                            <div class="addons-info-list-container medical-addons-info-list-container">
                                                <template v-for="optional_benefit in plans[index].plan_benefits" :key="optional_benefit">
                                                <div class="addons-info-box" v-if="optional_benefit.benefit_status=='optional'">
                                                    <div class="addons-info-area">
                                                        <div class="addons-header">
                                                            <div class="addons-icon">
                                                                <checkbox-prime @change="add_benefit_in_premium(index, optional_benefit, $event)" v-model="plans[index].selected_addons" :value="optional_benefit"/>
                                                            </div>
                                                            <div class="medical-addons-list-left">
                                                                <h2 class="addons-title">{{ optional_benefit.benefit_name }}</h2>
                                                                <p class="addons-description" v-if="optional_benefit.benefit_value!=null">
                                                                    {{optional_benefit.benefit_value}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="addons-price-add">
                                                        <div class="addons-prine-box">
                                                            <p class="m-0 addons-pricing-area">
                                                                <span class="currency-label">AED</span>
                                                                <span class="price-label">{{optional_benefit.benefit_amount}}</span>
                                                            </p>
                                                            <p class="m-0 per-year">per year</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </template>
                                                
                                            </div>
                                        </accordionTab-prime>
                                    </accordion-prime>
                                </div>
                            </div>
                            
                            <!-- / Motor listing -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="compare-items-bottom" style="display:flex !important;" v-if="medical_compare.length>0">
            <div class="container">
                <div class="compare-box-inner">
                    <div class="compare-left">
                        <div class="compare-item" v-for="(compare, index1) in medical_compare" :key="index1">
                            <div class="compare-item-info">
                                <label>{{compare.company_name}}</label>
                                <h2>AED {{compare.premium}}</h2>
                            </div>
                            <div class="compare-item-delete" @click="remove_compare_index(index1)">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>


                    </div>

                    <div class="compare-right">
                        <button-prime label="Compare All" @click="compare_all" class="compare-all-btn" />
                        <p class="clear-all" @click="clear_comparision_footer">Clear All</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Filter Modal For Mobile -->
    <dialog-prime v-model:visible="visible" header="Filters" :style="{ width: '25rem' }" position="bottom" :modal="true"
        :draggable="false" class="listing-filter-modal-mobile">
        <div>
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12 listing-filter-area-mobile">
                    <div class="left-filters-area">
                        <div class="filter-options-area">
                            <div class="filter-option-list mt-0">
                                <h4>Annual Premium</h4>

                                <div class="anual-price-range">
                                    <slider-prime v-model="valueAnual" class="w-full" />
                                    <div class="anual-price-text">
                                        <span>AED 4000</span>
                                        <span>AED 4000</span>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Hospital Network</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedHospitalNetwork" :options="hospitalNetwork"
                                        filter="true" optionLabel="name" placeholder="Select" class="w-full">
                                        <template #option="slotProps">
                                            <div class="flex align-items-center">
                                                <div class="card flex justify-content-center mr-2">
                                                    <checkbox-prime class="medical-filter-dropdown-checkbox"
                                                        v-model="checked" :binary="true" />
                                                </div>
                                                <div>{{ slotProps.option.name }}</div>
                                            </div>
                                        </template>
                                    </dropdown-prime>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Hospitalization</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedHopitalization" :options="hopitalization"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Outpatient</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedOutpatient" :options="outpatient"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Dental Coverage</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedDentalCoverage" :options="dentalCoverage"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Maternity Coverage</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedMaternityCoverage" :options="maternityCoverage"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list border-0 pb-0">
                                <h4>Medication Coverage</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedMedicationCoverage" :options="medicationCoverage"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-btn-area">
                                <button-prime label="Apply filters" class="apply-filter-btn" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </dialog-prime>
    <!-- / Filter Modal For Mobile -->

    <!-- Meternity Full detail Modal  -->
    <dialog-prime v-model:visible="fullDetailMaternityVisible" modal :draggable="false" header="Maternity Coverage" class="listing-full-detail-modal" :style="{ width: '45rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <p class="mb-5 mt-0">
            Maternity Coverage, designed to provide comprehensive support throughout your pregnancy journey. Our commitment is to ensure you receive the best care, from pre-natal to post-natal stages.
        </p>

        <div class="mb-5">
            <h4 class="detail-heading">Pre-Natal Care:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Regular Check-ups</h4>
                    <p>Access to a network of experienced obstetricians for routine examinations.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Diagnostic Tests</h4>
                    <p>Coverage for essential pre-natal tests ensuring the well-being of both mother and baby.</p>
                </div>
            </div>
        </div>

        <div class="mb-5">
            <h4 class="detail-heading">Delivery:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Hospital Coverage</h4>
                    <p>Choose from our extensive network of hospitals for a comfortable and secure delivery.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Labour and Delivery Expenses</h4>
                    <p>Coverage for hospital charges related to labor and delivery.</p>
                </div>
            </div>
        </div>

        <div class="mb-5">
            <h4 class="detail-heading">Post-Natal Care:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Post-Delivery Check-ups</h4>
                    <p>Continued coverage for post-natal consultations and care.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Well-Baby Check-ups</h4>
                    <p>Ensure your new born's health with coverage for well-baby check-ups.</p>
                </div>
            </div>
        </div>
    </dialog-prime>
    <!-- / Meternity Full detail Modal  -->

    <!-- Meternity Full detail Modal  -->
    <dialog-prime v-model:visible="fullDetailSurgeryVisible" modal :draggable="false" header="Surgery Coverage" class="listing-full-detail-modal" :style="{ width: '45rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <p class="mb-5 mt-0">
            Maternity Coverage, designed to provide comprehensive support throughout your pregnancy journey. Our commitment is to ensure you receive the best care, from pre-natal to post-natal stages.
        </p>

        <div class="mb-5">
            <h4 class="detail-heading">Pre-Natal Care:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Regular Check-ups</h4>
                    <p>Access to a network of experienced obstetricians for routine examinations.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Diagnostic Tests</h4>
                    <p>Coverage for essential pre-natal tests ensuring the well-being of both mother and baby.</p>
                </div>
            </div>
        </div>

        <div class="mb-5">
            <h4 class="detail-heading">Delivery:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Hospital Coverage</h4>
                    <p>Choose from our extensive network of hospitals for a comfortable and secure delivery.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Labour and Delivery Expenses</h4>
                    <p>Coverage for hospital charges related to labor and delivery.</p>
                </div>
            </div>
        </div>

        <div class="mb-5">
            <h4 class="detail-heading">Post-Natal Care:</h4>
            
            <div class="full-detail-list-item">
                <h4 class="number-text">1.</h4>

                <div class="full-detail-info-list">
                    <h4>Post-Delivery Check-ups</h4>
                    <p>Continued coverage for post-natal consultations and care.</p>
                </div>
            </div>

            <div class="full-detail-list-item">
                <h4>2.</h4>

                <div class="full-detail-info-list">
                    <h4>Well-Baby Check-ups</h4>
                    <p>Ensure your new born's health with coverage for well-baby check-ups.</p>
                </div>
            </div>
        </div>
    </dialog-prime>
    <!-- / Meternity Full detail Modal  -->
    <toast-prime />
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios'
import $ from 'jquery'
// import moment from 'moment'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    data() {
        return {
            modules: [
                Navigation, Pagination, Autoplay, Scrollbar
            ],
            quotation : {},
            plans : [],
            loading_buy_now_button : false,
            medical_compare : [],
            hospital_network_filters : [],
            hospital_network_filters_selected : [],
            co_pay_option_filters : [],
            co_pay_option_filters_selected : [],
            io_filters : [{name : 'Inpatient'}, {name : 'Outpatient'}],
            io_filters_selected : [],
            sort : [{ name: 'High to Low Premium', id: 'high-to-low-premium' },{ name: 'Low to High Premium', id: 'low-to-high-premium' }],
            selected_sort : null
        }
    },
    components: {
        HeaderJourney,
        Swiper,
        SwiperSlide,
    },
    mounted() {
        this.get_quotation_details();
        this.get_hospital_networks();
        this.get_co_pay_options();
        $(document).ready(function () {
            $(document).on('click', '.panel-open-btn', function () {
                if ($(this).parents('.tabs-listing-box').hasClass('accordion-open')) {
                    $(this).parents('.tabs-listing-box').removeClass('accordion-open')
                } else {
                    $('.tabs-listing-box').removeClass('accordion-open')
                    $(this).parents('.tabs-listing-box').addClass('accordion-open')
                }
            })
        });
    },
    methods: {
        get_hospital_networks : function() {
            axios.get(this.api_url+'medical/get_hospital_networks').then((response) => {
                this.hospital_network_filters = response.data.data.hospital_networks;
            });
        },
        get_co_pay_options : function() {
            axios.get(this.api_url+'medical/get_co_pay_options').then((response) => {
                this.co_pay_option_filters = response.data.data;
            });
        },
        generate_pdf : function (plan) {
            var url = this.api_url+'medical/download-company-plan-quotation?quotation_id='+this.$route.params.quotation_id+'&plan_id='+plan.plan_id+'&plan_name='+plan.plan_name+'&company_name='+plan.company_name+'&plan_premium='+plan.premium+'&tax='+plan.tax_amount;
           console.log('url', url);
            window.open(url, '_blank');
        },
        add_benefit_in_premium : function(plan_index, benefit_data, event) {
            if (event.target.checked) {
                this.plans[plan_index].gross_premium = parseFloat(this.plans[plan_index].gross_premium)+parseFloat(benefit_data.benefit_amount); 
            } else {
                this.plans[plan_index].gross_premium = parseFloat(this.plans[plan_index].gross_premium)-parseFloat(benefit_data.benefit_amount); 
            }
        },
        go_back_to_medical_journey_page : function(step) {
            if (localStorage.getItem('mebrokers.customer.medical.journey') != null) {
                var parse = JSON.parse(localStorage.getItem('mebrokers.customer.medical.journey'));
                parse.step = step;
                localStorage.setItem('mebrokers.customer.medical.journey', JSON.stringify(parse))
            }
            this.$router.push({
                name : 'medical-journey',
            });
        },
        get_quotation_details : function() {
            axios.get(this.api_url+'medical/get_quotation_details', {
                params : {
                    quotation_id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.quotation = response.data.data.quotation;
                this.get_plans();
            });
        },
        get_plans : function get_plans() {
            axios.get(this.api_url+'medical/get_plans', {
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    hospital_nework_id : this.hospital_network_filters_selected,
                    co_pay : this.co_pay_option_filters_selected,
                    io : this.io_filters_selected,
                    sort_by : this.selected_sort != null ? this.selected_sort.id : null,
                }
            }).then((response) => {
                this.plans = response.data.data;
            });
        },
        update_quotation_details : function(data) {
            this.loading_buy_now_button = true;
            axios.get(this.api_url+'medical/update_quotation_details',{
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    company_id : data.company_id,
                    plan_id : data.plan_id,
                    additional_coverages : data.selected_addons,
                    premium : data.premium,
                    status : 'pending-review'
                }
            }).then((response) => {
                // this.loading_buy_now_button = false;
                if (response.data.data == true) {
                    if (this.$route.query.is_from != undefined && this.$route.query.is_from == 'admin') {
                        this.$toast.add({ severity: 'success', detail: 'Changes saved!', life: 3000 });
                    } else {

                        axios.get(this.api_url+'medical/send_email_and_sms_on_request_complete',{
                            params : {
                                quotation_id : this.$route.params.quotation_id,
                            }
                        }).then((response) => {
                            this.loading_buy_now_button = false;
                            if (response.data.data.send_email == true) {
                                this.$router.push({
                                    path : '/medical/purchase-completed/'+this.$route.params.quotation_id,
                                });
                            }
                        });

                        // this.$router.push({
                        //     path : '/medical/purchase-completed/'+this.$route.params.quotation_id,
                        // });
                    }
                    
                }
            });
        },
        open_compare_footer(data) {
            console.log('data', data)
            if(this.medical_compare.length < 3){
                var validation_for_same = true;
                for (var key in this.medical_compare) {
                    if ((this.medical_compare[key].plan_id == data.plan_id) && (this.medical_compare[key].company_id == data.company_id)) {
                        validation_for_same = false;
                        break;
                    }
                }
                if (!validation_for_same) {
                    this.$toast.add({ severity: 'error', detail: 'This plan is alread selected!', life: 3000 });
                    return false;
                }
            }

            if(this.medical_compare.length == 3) {
                this.$toast.add({ severity: 'error', detail: 'Maximum range for comparison is only 3!', life: 3000 });
                return false;
            } else {
                this.medical_compare.push({
                    company_id : data.company_id,
                    plan_id : data.plan_id,
                    plan_name : data.plan_name,
                    company_name : data.company_name,
                    premium : data.premium,
                });
            }
        },
        clear_comparision_footer() {
            this.medical_compare = [];
            localStorage.removeItem('mebrokers.customer.medical.plans.comparison');
        },
        remove_compare_index : function(ind) {
            this.medical_compare.splice(ind, 1); 
        },
        compare_all : function() {
            localStorage.removeItem('mebrokers.customer.medical.plans.comparison');
            localStorage.setItem('mebrokers.customer.medical.plans.comparison', JSON.stringify(this.medical_compare));
            this.$router.push({
                path : '/medical/comparison-sheet/'+this.$route.params.quotation_id,
            });
        },
    }
}
</script>

<script setup>
import { ref } from 'vue';
const active = ref(1);



const checked = ref(false);


const selectedHospitalNetwork = ref();
const hospitalNetwork = ref([
    { name: 'Name', id: '1' },
    { name: 'Name', id: '2' },
    { name: 'Name', id: '3' },
    { name: 'Name', id: '4' }
]);

const selectedHopitalization = ref();
const hopitalization = ref([
    { name: 'Included', id: '1' },
    { name: 'Excluded', id: '2' }
]);

const selectedOutpatient = ref();
const outpatient = ref([
    { name: 'Included', code: 'in' },
    { name: 'Excluded', code: 'ex' }
]);

const selectedDentalCoverage = ref();
const dentalCoverage = ref([
    { name: 'Included', code: 'in' },
    { name: 'Excluded', code: 'ex' }
]);

const selectedMaternityCoverage = ref();
const maternityCoverage = ref([
    { name: 'Included', code: 'in' },
    { name: 'Excluded', code: 'ex' }
]);

const selectedMedicationCoverage = ref();
const medicationCoverage = ref([
    { name: 'Included', code: 'in' },
    { name: 'Excluded', code: 'ex' }
]);

const valueAnual = ref(30);

const visible = ref(false);
const fullDetailMaternityVisible = ref(false);
const fullDetailSurgeryVisible = ref(false);
</script>

<style>

</style>