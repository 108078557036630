<template>
    <HeaderJourney />

    <div class="main-listing-page travel-listing-page">
        <div class="container">
            <div class="grid ml-0 mr-0">
                <div class="col-12 md:col-12 lg:col-12">
                    <div class="flow-option-back flow-option-back-listing">
                        <ul class="flow-option-back-ul">
                            <li class="flow-option-back-li" @click="go_back_to_travel_journey_page('user_detail')">
                                <i class="pi pi-arrow-left"></i>
                                Back
                            </li>
                            <li class="inquiry-number">
                                <span>Inquiry Number:</span> <span>{{ ('quotation_number' in quotation) ?
                                    quotation.quotation_number : '-'}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="grid ml-0 mr-0 flow-info-editable-box">
                <div class="col-12 md:col-12 lg:col-12">
                    <swiper :slides-per-view="('travel_type_id' in quotation) && quotation.travel_type_id == 2 ? 4 : 3" :space-between="0" setWrapperSize="ture" :navigation="true" :loop="true"
                    :pagination="pagination" :modules="modules" :breakpoints="{
                            '@0.00': { slidesPerView: 1, spaceBetween: 0 },
                            '@0.75': { slidesPerView: 2, spaceBetween: 0 },
                            '@1.00': { slidesPerView: ('travel_type_id' in quotation) && quotation.travel_type_id == 2 ? 4 : 3, spaceBetween: 0 },
                            '@1.50': { slidesPerView: ('travel_type_id' in quotation) && quotation.travel_type_id == 2 ? 4 : 3, spaceBetween: 0 },
                    }" @swiper="onSwiper" @slideChange="onSlideChange">
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>1</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Travel Type</p>
                                                <h4 class="flow-type-title">{{ ('travel_type' in quotation) ? quotation.travel_type.name :
                                                    '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_travel_journey_page('travel_type')" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide v-if="('travel_type_id' in quotation) && quotation.travel_type_id == 2">
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>2</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Travel Area</p>
                                                <h4 class="flow-type-title">{{ ('travel_area' in quotation) ? quotation.travel_area.name :
                                                    '-'}}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_travel_journey_page('travel_area')" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>3</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Travel Date</p>
                                                <h4 class="flow-type-title">{{ ('departure_date' in quotation) ?
                                                    moment(quotation.departure_date).format("DD MMMM YYYY") : '-' }}</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_travel_journey_page('dates')"
                                                class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="flow-option-editable">
                            <div class="flow-option-list">
                                <ul class="flow-option-list-ul">
                                    <li class="flow-option-list-li">
                                        <div class="flow-info">
                                            <div class="flow-number-badge">
                                                <span>4</span>
                                            </div>
                                            <div class="flow-type">
                                                <p class="flow-type-text">Traveller</p>
                                                <h4 class="flow-type-title">{{ ('traveller_adults' in quotation) ? quotation.traveller_adults.length : '0' }} Adults, {{ ('traveller_childs' in quotation) ? quotation.traveller_childs.length : '0' }} Childs, {{ ('traveller_seniors' in quotation) ? quotation.traveller_seniors.length : '0' }} Seniors</h4>
                                            </div>
                                        </div>
                                        <div class="flow-action">
                                            <div @click="go_back_to_travel_journey_page('travelers')" class="flow-edit-box">
                                                <span><i class="pi pi-pencil"></i></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </swiper-slide>
                   
                    </swiper>
                </div>
            </div>

            <div class="grid ml-0 mr-0 mb-4">
                <div class="col-12 md:col-4 lg:col-3 listing-filter-area">
                    <div class="left-filters-area">
                        <div class="filter-options-area">
                            <!-- <div class="filter-option-list mt-0">
                                <h4>Annual Premium</h4>

                                <div class="anual-price-range">
                                    <slider-prime v-model="valueAnual" class="w-full" />
                                    <div class="anual-price-text">
                                        <span>AED 500</span>
                                        <span>AED 500,000</span>
                                    </div>
                                </div>
                            </div> -->

                            <div class="filter-option-list mt-0">
                                <h4>Duration of Travel</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedDropdownOne" :options="dropdownOne"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Type of Travel</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedDropdownTwo" :options="dropdownTwo"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Coverage Type</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedDropdownThree" :options="dropdownThree"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list border-0 pb-0">
                                <h4>Destination of travel </h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedDropdownFour" :options="dropdownFour"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-8 lg:col-9">
                    <div class="right-listing-items-area">
                        <div class="listing-tabs-container">
                            <button-prime class="filters p-0 listing-filter-btn-mobile" aria-label="filters"
                                @click="visible = true">
                                <span class=" px-3">Filter</span>
                                <img src="../../assets/images/sort.svg">
                            </button-prime>

                            <div class="listing-tab-panel"></div>

                            <div class="listing-sort-container">
                                <dropdown-prime v-model="selectedSort" :options="sorting" optionLabel="name"
                                    placeholder="Sort By" class="w-full" />
                            </div>
                        </div>

                        <div class="tabs-listing-content">
                            <!-- Motor listing -->
                            <div v-for="(plan, index) in plans" :key="index" class="tabs-listing-box">
                                <div class="listing-top-header">
                                    <div class="logo-box-container">
                                        <div class="logo-box">
                                            <img :src="plan.company_logo">
                                        </div>
                                        <div class="info-listing-text">
                                            <p class="company-name-text">{{ plan.company_name }}</p>
                                            <p class="plan-name-text">{{ plan.plan_name }} - {{ plan.plan_description }}</p>
                                        </div>
                                    </div>
                                    <div class="header-info-right">
                                        <div class="currency-info">
                                            <div class="annual-premium">
                                                <span class="regular-text">Annual Premium</span>
                                                <span class="bold-text">AED {{ plan.premium }}</span>
                                            </div>
                                            <div class="vat">
                                                <span class="regular-text">VAT (5%)</span>
                                                <span class="bold-text">AED {{ plan.tax_amount }}</span>
                                            </div>
                                        </div>
                                        <div class="btn-right-area">
                                            <small>Pay in 4 installments at AED 34.09/month</small>
                                            <button-prime label="Buy Now" class="buy-now-btn" :loading="buy_now_loader" @click="orient_buy_plan(plan)" />
                                            <div class="footer-download-send-btn">
                                                <button-prime class="travel-pi-download" icon="pi pi-download" @click="generate_pdf(plan)" severity="secondary" outlined />
                                                <!-- <button-prime icon="pi pi-send" severity="secondary" outlined /> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="listing-main-content">
                                    <div class="middle-content-box medi-middle-content-box">
                                        <div class="icon-area">
                                            <img src="../../assets/images/Icons/network.svg">
                                        </div>
                                        <div class="info-area">
                                            <h4>Emergency Medical</h4>
                                            <p>AED 100,000 per person</p>

                                            <a class="icon-area-anchor" href="#">See Network</a>
                                        </div>
                                    </div>
                                    <div class="middle-content-box medi-middle-content-box">
                                        <div class="icon-area">
                                            <img src="../../assets/images/Icons/cross.svg">
                                        </div>
                                        <div class="info-area">
                                            <h4>Trip Cancellation</h4>
                                            <p>Upto AED 5,000</p>

                                            <a class="icon-area-anchor" href="#">See full details</a>
                                        </div>
                                    </div>
                                    <div class="middle-content-box medi-middle-content-box">
                                        <div class="icon-area">
                                            <img src="../../assets/images/Icons/cross.svg">
                                        </div>
                                        <div class="info-area">
                                            <h4>Accidental Death</h4>
                                            <p>Upto AED 500,000</p>

                                            <a class="icon-area-anchor" href="#">See full details</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="listing-footer">
                                    <div class="footer-likn-text">
                                        <a href="#">
                                            Terms & Condition
                                        </a>
                                    </div>
                                    <div class="footer-collapse">
                                        <button-prime class="panel-open-btn" label="Explore Benefits and Ad-Ons"
                                            severity="secondary" outlined />
                                    </div>
                                    <div class="compare-box">
                                        <button-prime class="p-0 transparent" aria-label="compare">
                                            <span class="px-2">Add to Compare</span>
                                            <i class="pi pi-plus px-2"></i>
                                        </button-prime>
                                    </div>
                                </div>

                                <div class="listing-footerInfo">
                                    <accordion-prime v-model:activeIndex="active">
                                        <accordionTab-prime>
                                            <h2 class="listing-footerInfo-title">What's included in this policy</h2>

                                            <div
                                                class="includes-info-list-container includes-info-list-container-travel">
                                                <div class="includes-info-list">
                                                    <div class="includes-info-list-icon">
                                                        <img src="../../assets/images/Icons/checkmark.svg">
                                                    </div>
                                                    <h2 class="includes-info-title">Medical Expenses Coverage: Ensures
                                                        medical treatment and hospital bills are covered if the
                                                        traveler is injured or falls ill abroad.</h2>
                                                </div>

                                                <div class="includes-info-list">
                                                    <div class="includes-info-list-icon">
                                                        <img src="../../assets/images/Icons/checkmark.svg">
                                                    </div>
                                                    <h2 class="includes-info-title">Emergency Transport & Repatriation:
                                                        Provides coverage for emergency transport, such as an
                                                        ambulance, and travel home on medical grounds.</h2>
                                                </div>

                                                <div class="includes-info-list">
                                                    <div class="includes-info-list-icon">
                                                        <img src="../../assets/images/Icons/checkmark.svg">
                                                    </div>
                                                    <h2 class="includes-info-title">Personal Injury & Accidents: Offers
                                                        coverage for personal injury and accidents caused by the
                                                        traveller.</h2>
                                                </div>

                                                <div class="includes-info-list">
                                                    <div class="includes-info-list-icon">
                                                        <img src="../../assets/images/Icons/checkmark.svg">
                                                    </div>
                                                    <h2 class="includes-info-title">Lost, Stolen, or Damaged Items:
                                                        Covers lost, stolen, or damaged items, including baggage,
                                                        passports, and money.</h2>
                                                </div>

                                                <div class="includes-info-list">
                                                    <div class="includes-info-list-icon">
                                                        <img src="../../assets/images/Icons/checkmark.svg">
                                                    </div>
                                                    <h2 class="includes-info-title">Trip Cancellation, Delay, or
                                                        Interruption: Protects against trip cancellation, delay, or
                                                        interruption.</h2>
                                                </div>

                                                <div class="includes-info-list">
                                                    <div class="includes-info-list-icon">
                                                        <img src="../../assets/images/Icons/checkmark.svg">
                                                    </div>
                                                    <h2 class="includes-info-title">Missed Departure: Provides coverage
                                                        for missed transport or delayed departure for reasons
                                                        beyond the traveller's control.</h2>
                                                </div>
                                            </div>

                                            <h2 class="listing-footerInfo-title mt-5">Choose the add ons</h2>

                                            <div class="addons-info-list-container medical-addons-info-list-container">
                                                <div v-for="item in plan.addons" :key="item" class="addons-info-box">
                                                    <div class="addons-info-area">
                                                        <div class="addons-header">
                                                            <div class="addons-icon">
                                                                <checkbox-prime v-model="plan.selected_addons" :value="item" />
                                                            </div>
                                                            <div class="medical-addons-list-left">
                                                                <h2 class="addons-title">{{ item.cover_name }}</h2>
                                                                <!-- <p class="addons-description">
                                                                    Coverage for loss passport caused by any means.
                                                                </p> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="addons-price-add">
                                                        <div class="addons-prine-box">
                                                            <p class="m-0 addons-pricing-area">
                                                                <span class="currency-label">AED</span>
                                                                <span class="price-label">{{ item.cover_premium }}</span>
                                                            </p>
                                                            <p class="m-0 per-year">per year</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </accordionTab-prime>
                                    </accordion-prime>
                                </div>
                            </div>
                            
                            <!-- / Motor listing -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="compare-items-bottom hidden">
            <div class="container">
                <div class="compare-box-inner">
                    <div class="compare-left">
                        <div class="compare-item">
                            <div class="compare-item-info">
                                <label>Tawuniya Insurance</label>
                                <h2>AED 2,129</h2>
                            </div>
                            <div class="compare-item-delete">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>

                        <div class="compare-item">
                            <div class="compare-item-info">
                                <label>Tawuniya Insurance</label>
                                <h2>AED 2,129</h2>
                            </div>
                            <div class="compare-item-delete">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>

                        <div class="compare-item">
                            <div class="compare-item-info">
                                <label>Tawuniya Insurance</label>
                                <h2>AED 2,129</h2>
                            </div>
                            <div class="compare-item-delete">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>
                    </div>

                    <div class="compare-right">
                        <button-prime label="Compare All" class="compare-all-btn" />
                        <p class="clear-all">Clear All</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Filter Modal For Mobile -->
    <dialog-prime v-model:visible="visible" header="Filters" :style="{ width: '25rem' }" position="bottom" :modal="true"
        :draggable="false" class="listing-filter-modal-mobile">
        <div>
            <div class="grid ml-0 mr-0 mb-4">
                <div class="col-12 md:col-12 lg:col-12 listing-filter-area-mobile">
                    <div class="left-filters-area">
                        <div class="filter-options-area">
                            <div class="filter-option-list mt-0">
                                <h4>Annual Premium</h4>

                                <div class="anual-price-range">
                                    <slider-prime v-model="valueAnual" class="w-full" />
                                    <div class="anual-price-text">
                                        <span>AED 500</span>
                                        <span>AED 500,000</span>
                                    </div>
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Duration of Travel</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedDropdownOne" :options="dropdownOne"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Type of Travel</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedDropdownTwo" :options="dropdownTwo"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list">
                                <h4>Coverage Type</h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedDropdownThree" :options="dropdownThree"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>

                            <div class="filter-option-list border-0 pb-0">
                                <h4>Destination of travel </h4>

                                <div class="flex medical-filter-dropdown">
                                    <dropdown-prime v-model="selectedDropdownFour" :options="dropdownFour"
                                        optionLabel="name" placeholder="Select" class="w-full" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </dialog-prime>
    <!-- / Filter Modal For Mobile -->
</template>

<script>
import HeaderJourney from '../Header/HeaderJourney.vue'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    data() {
        return {
            modules: [
                Navigation, Pagination, Autoplay, Scrollbar
            ],
            quotation : {},
            plans : null,
            filters : {},
            travel_compare : [],
            buy_now_loader : false
        }
    },
    components: {
        HeaderJourney,
        Swiper,
        SwiperSlide,
    },
    mounted() {
        this.get_quotation_details();

        $(document).ready(function () {
            $(document).on('click', '.panel-open-btn', function () {
                if ($(this).parents('.tabs-listing-box').hasClass('accordion-open')) {
                    $(this).parents('.tabs-listing-box').removeClass('accordion-open')
                } else {
                    $('.tabs-listing-box').removeClass('accordion-open')
                    $(this).parents('.tabs-listing-box').addClass('accordion-open')
                }
            })
        });
    },
    methods: {
        generate_pdf : function (plan) {
            console.log('plan', plan)
            var url = this.api_url+'travel/download-company-plan-quotation?quotation_id='+this.$route.params.quotation_id+'&plan_id='+plan.plan_id+'&plan_name='+plan.plan_name+'&plan_description='+plan.plan_description+'&plan_premium='+plan.premium+'&tax='+plan.tax_amount;
            window.open(url, '_blank');
        },
        apply_cover : function(key, addon) {
            console.log('key, addon', key, addon)
            this.plans[key].selected_addons.push(addon);
            console.log('this.plans[key]', this.plans[key]);
        },
        go_back_to_travel_journey_page : function(step) {
            if (localStorage.getItem('mebrokers.customer.travel.journey') != null) {
                var parse = JSON.parse(localStorage.getItem('mebrokers.customer.travel.journey'));
                parse.step = step;
                localStorage.setItem('mebrokers.customer.travel.journey', JSON.stringify(parse))
            }
            this.$router.push({
                name : 'travel-journey',
            });
        },
        get_quotation_details : function() {
            axios.get(this.api_url+'travel/get_quotation_details', {
                params : {
                    quotation_id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.quotation = response.data.data.quotation;
                this.get_plans();
            });
        },
        get_plans : function() {
            axios.get(this.api_url+'travel/get_plans', {
                params : {
                    quotation_id : this.$route.params.quotation_id
                }
            }).then((response) => {
                this.plans = response.data.data.plans;
                this.quotation.external_quotation_number = response.data.data.quotation_number;
            });
        },
        orient_buy_plan : function(item) {
            this.buy_now_loader = true;
            axios.get(this.api_url+'travel/orient_buy_plan', {
                params : {
                    quotation_id : this.$route.params.quotation_id,
                    external_quotation_number : this.quotation.external_quotation_number,
                    data : item
                }
            }).then((response) => {
                if (response.data.data.status == true) {
                    this.$router.push({
                        path : '/travel/checkout/'+this.$route.params.quotation_id,
                    });
                    // axios.get(this.api_url+'travel/send_email_and_sms_on_request_complete',{
                    //     params : {
                    //         quotation_id : this.$route.params.quotation_id,
                    //         status : 'pending-review',
                    //     }
                    // }).then((response) => {
                    //     this.buy_now_loader = false;
                    //     if (response.data.data.send_email == true) {
                    //         this.$router.push({
                    //             path : '/travel/checkout/'+this.$route.params.quotation_id,
                    //         });
                    //     }
                    // });

                    
                }
                
            });
        },
    }
}
</script>

<script setup>
import { ref } from 'vue';
const active = ref(1);



const selectedSort = ref();
const sorting = ref([
    { name: 'Sort by', id: 'one' },
    { name: 'Sort by', code: 'two' }
]);

const selectedDropdownOne = ref();
const dropdownOne = ref([
    { name: 'Included', id: '1' },
    { name: 'Excluded', id: '2' }
]);

const selectedDropdownTwo = ref();
const dropdownTwo = ref([
    { name: 'Included', code: 'in' },
    { name: 'Excluded', code: 'ex' }
]);

const selectedDropdownThree = ref();
const dropdownThree = ref([
    { name: 'Included', code: 'in' },
    { name: 'Excluded', code: 'ex' }
]);

const selectedDropdownFour = ref();
const dropdownFour = ref([
    { name: 'Included', code: 'in' },
    { name: 'Excluded', code: 'ex' }
]);

const valueAnual = ref(30);

const visible = ref(false);
</script>

<style></style>